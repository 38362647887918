import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class EstimationShapeFieldItem {
  @JsonProperty() title!: string

  @JsonProperty() value?: string

  @JsonProperty() key!: string

  @JsonProperty() unit!: string
}
