



































































































































































































































































import Component, { mixins } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import EstimationProductCategory from "@/types/Estimation/EstimationProductCategory"
import EstimationApi from "@/api/Estimation/EstimationApi"
import EstimationResult from "@/types/Estimation/EstimationResult"
import { deserialize } from "typescript-json-serializer"
import EstimationColorValue from "@/types/Estimation/EstimationColorValue"
import EstimationShapeValue from "@/types/Estimation/EstimationShapeValue"
import DiamondFile from "@/types/DiamondFile"
import EstimationShapeField from "@/types/Estimation/EstimationShapeField"
import html2canvas from "html2canvas"
import BreakpointMixin from "../../types/BreakpointMixin"
import InputMixin from "../../types/InputMixin"

@Component({
  name: "CalculateForm"
})
export default class CalculateForm extends mixins(InputMixin, BreakpointMixin) {
  @Prop() categoryId!: number

  @Prop() groupId!: number

  private shapes: EstimationShapeValue[] = []

  private colors: EstimationColorValue[] = []

  private fields: EstimationShapeField = new EstimationShapeField()

  private colorId = 1

  private shapeId = 1

  private model = {}

  private results: EstimationResult[] = []

  private category = new EstimationProductCategory()

  private calculating = false

  private productImage = new DiamondFile()

  private takingSnapshot = false

  private error = {
    show: false,
    message: ""
  }

  @Watch("groupId")
  private onGroupChange() {
    this.fetchData()
      .then(() => {
        this.colorId = _.first(this.colors)?.id || -1
        this.shapeId = _.first(this.shapes)?.id || -1
      })
      .then(this.resetModel)
      .then(this.resetResult)
  }

  @Watch("colorId")
  private onColorChange() {
    this.getImage()
  }

  @Watch("categoryId")
  private onCategoryChange() {
    this.fetchData()
      .then(() => {
        this.colorId = _.first(this.colors)?.id || -1
        this.shapeId = _.first(this.shapes)?.id || -1
      })
      .then(this.resetModel)
      .then(this.resetResult)
  }

  @Watch("shapeId")
  private onShapeChange() {
    this.fetchData().then(this.resetModel).then(this.resetResult)
  }

  @Watch("shapes")
  onShapeChanges() {
    this.resetModel()
  }

  private get calculated() {
    return this.results.length > 0
  }

  private get shapeTitle() {
    return this.category?.shape?.title || ""
  }

  private get colorTitle() {
    return this.category?.color?.title || ""
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData().then(() => {
      this.colorId = _.first(this.colors)?.id || -1
      this.shapeId = _.first(this.shapes)?.id || -1
      this.results = []
      this.error = {
        show: false,
        message: ""
      }
      window.scrollTo({ top: 0 })
    })
  }

  private screenShot() {
    if (this.results.length === 0) return
    this.takingSnapshot = true
    // eslint-disable-next-line
    const elm = document.getElementById("screen")!
    let y = 0
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      y = 700
    } else if (bp.mdAndUp) {
      y = 600
    } else {
      y = 360
    }
    html2canvas(elm, {
      backgroundColor: "#ffffff",
      useCORS: true,
      y,
      // eslint-disable-next-line no-restricted-globals
      height: elm.clientHeight
    })
      .then((canvas: HTMLCanvasElement) => {
        const imagename = "screenshot"
        // @ts-ignoreß
        if (navigator.msSaveBlob) {
          // IE10+
          // @ts-ignore
          const blob = canvas.msToBlob()
          // @ts-ignore
          navigator.msSaveBlob(blob, imagename)
        } else {
          const imageurl = canvas.toDataURL("image/png")
          this.fileDownload(imageurl, imagename)
        }
      })
      .finally(() => {
        this.takingSnapshot = false
      })
  }

  // eslint-disable-next-line class-methods-use-this
  fileDownload(downloadUrl: string, downloadName: string) {
    const aLink = document.createElement("a")
    aLink.style.display = "none"
    aLink.href = downloadUrl
    aLink.download = `${downloadName}.jpg`
    // Trigger click-then remove
    document.body.appendChild(aLink)
    aLink.click()
    document.body.removeChild(aLink)
  }

  private mounted() {
    this.fetchData().then(() => {
      this.colorId = _.first(this.colors)?.id || -1
      this.shapeId = _.first(this.shapes)?.id || -1
    })
  }

  private selectShape(shapeId: number) {
    this.shapeId = shapeId
    this.getImage()
    this.resetModel()
    this.resetResult()
  }

  private getImage() {
    if (this.categoryId >= 11) {
      this.productImage = this.category.file
      return Promise.resolve(null)
    }
    return EstimationApi.getEstimationProduct(this.colorId, this.categoryId, this.shapeId).then(({ data }) => {
      this.productImage = deserialize<DiamondFile>(data, DiamondFile)
    })
  }

  private fetchData() {
    if (this.categoryId <= 0) return new Promise(console.log)
    return EstimationApi.getProductCategoryDetail(this.categoryId)
      .then(({ data }) => {
        this.category = deserialize<EstimationProductCategory>(data, EstimationProductCategory)
      })
      .then(this.getImage)
      .then(this.fetchFields)
      .then(() => {
        this.shapes = this.category.shape?.value || []
        if (this.categoryId !== 10) this.colors = this.category.color?.value || []
        else this.colors = this.fields.thickness?.value || []
      })
      .then(this.resetModel)
      .catch(console.warn)
  }

  private fetchFields() {
    return EstimationApi.listEstimationFields(this.categoryId, this.shapeId).then(({ data }) => {
      this.fields = deserialize<EstimationShapeField>(data, EstimationShapeField)
      if (this.categoryId === 10) this.colors = this.fields.thickness?.value || []
    })
  }

  @Watch("colors")
  onColorsChange() {
    if (this.categoryId === 10) {
      this.colorId = _.first(this.colors)?.id || -1
    }
  }

  private resetResult() {
    this.results = []
  }

  private resetModel() {
    this.model = _.reduce(_.map(this.fields.field, "key"), (acc, v) => _.extend(acc, { [v]: 0 }), {})
  }

  private get totalPrice() {
    return this.sumResult(this.results, "price")
  }

  private sumResult(arr: any[], key: string) {
    if (!arr || !arr.length) return 0
    let newArr = arr.map((item) => {
      item[key] = item[key] ? parseFloat(item[key].replace(",", "")) : 0
      return item
    })

    return this.filterNumberWithCommas(newArr.reduce((a: any, b: any) => a + (b[key] || 0), 0).toFixed(2))
  }

  private filterNumberWithCommas = (number: string | number) => {
    if (number === null || number === "" || number == undefined) return "0"
    const _num = number.toString()
    const [count, dec] = _num.split(".")
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (dec ? `.${dec.substring(0, 2)}` : "")
  }

  private doCalculate() {
    this.calculating = true
    const keys = Object.keys(this.model)
    if (!!keys) {
      keys.forEach((key: string) => {
        //@ts-ignore
        this.model[`${key}`] = parseFloat(this.model[`${key}`])
      })
    }

    EstimationApi.calculate(this.shapeId, this.categoryId, this.colorId, this.model)
      .then(({ data }) => {
        this.results = _.map(data, (d) => deserialize<EstimationResult>(d, EstimationResult))
        this.error = {
          show: false,
          message: ""
        }
      })
      .then(() => {
        this.$scrollTo("#resultContainer", {
          y: true,
          offset: -250,
          duration: 500
        })
      })
      .catch((err) => {
        const { message } = err.response.data
        this.error = {
          show: true,
          message: `${message[`${this.$i18n.locale.toUpperCase()}`]}`
        }
      })
      .finally(() => {
        this.calculating = false
      })
  }

  private get detailImageSize() {
    // return [width, height]
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return ["612px", "612px"]
    }
    if (bp.mdAndUp) {
      return ["429px", "429px"]
    }
    return ["278px", "126px"]
  }

  private get detailImageBoxSize() {
    // return [width, height]
    const bp = this.$vuetify.breakpoint
    let size = []
    if (bp.xlOnly) {
      size = ["700px", "700px"]
    } else if (bp.mdAndUp) {
      size = ["500px", "500px"]
    } else {
      size = ["100%", "158px"]
    }
    return size
  }

  private get shapeImageSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 229
    }
    if (bp.mdAndUp) {
      return 153
    }
    return 153
  }

  private get shapeImageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 327
    }
    if (bp.mdAndUp) {
      return 220
    }
    return 220
  }
}
