var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[(_vm.mobileScreen)?_c('div',[_c('v-row',{staticClass:"mt-4 mx-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{staticClass:"border-radius-10",attrs:{"solo":"","flat":"","hide-details":"","height":_vm.responsiveTextFieldHeight,"items":_vm.productGroups,"item-text":"title","item-value":"productGroupId","value":_vm.groupId,"append-icon":"fas fa-chevron-down","background-color":"grey lighten-3"},on:{"change":_vm.changeTab},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex mt-auto align-center",style:({ height: _vm.responsiveTextFieldHeight })},[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,false,2401730490)})],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{staticClass:"border-radius-10",attrs:{"solo":"","flat":"","hide-details":"","height":_vm.responsiveTextFieldHeight,"items":_vm.productCategories,"item-text":"title","item-value":"productCategoryId","value":_vm.categoryId,"append-icon":"fas fa-chevron-down","background-color":"grey lighten-3"},on:{"change":_vm.changeSubTab},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex mt-auto align-center",style:({ height: _vm.responsiveTextFieldHeight })},[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,false,2401730490)})],1)],1)],1):_c('div',[_c('v-tabs',{staticStyle:{"max-height":"80px","min-height":"50px"},attrs:{"value":_vm.tab,"flat":"","dark":"","height":_vm.responsiveTabHeight,"background-color":"primary"}},[_c('v-tabs-slider',{staticStyle:{"bottom":"1px","position":"absolute"},attrs:{"color":"white"}}),_vm._l((_vm.productGroups),function(item,idx){return _c('v-hover',{key:("productGroupTab" + (item.title)),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-tab',{staticClass:"tab-font-large white--text",class:_vm.getGroupTabClass(idx),on:{"click":function($event){return _vm.changeTab(item.productGroupId)}}},[_c('router-link',{staticClass:"text-decoration-none white--text",attrs:{"to":_vm.generateRoute(item.productGroupId)}},[_vm._v(" "+_vm._s(item.title)+" ")]),(idx + 1 !== _vm.productGroups.length)?_c('div',{staticClass:"vertical-tab-divider"}):_vm._e(),(idx !== _vm.tab)?_c('v-fade-transition',[(hover)?_c('div',{staticClass:"custom-tab-hover"}):_vm._e()]):_vm._e()],1)]}}],null,true),model:{value:(_vm.productGroups[idx].hover),callback:function ($$v) {_vm.$set(_vm.productGroups[idx], "hover", $$v)},expression:"productGroups[idx].hover"}})})],2),(_vm.productCategories.length > 0)?_c('v-tabs',{staticClass:"mb-12",staticStyle:{"max-height":"80px","min-height":"50px"},attrs:{"value":_vm.subTab,"flat":"","dark":"","height":_vm.responsiveTabHeight,"background-color":"white"}},[_c('v-tabs-slider',{staticStyle:{"bottom":"1px","position":"absolute"},attrs:{"color":"primary"}}),_vm._l((_vm.productCategories),function(item,idx){return _c('v-hover',{key:("aboutUsTab" + (item.title)),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-tab',{staticClass:"tab-font",class:_vm.getCategoryTabClass(idx),on:{"click":function($event){return _vm.changeSubTab(item.productCategoryId)}}},[_c('router-link',{staticClass:"text-decoration-none black--text",attrs:{"to":_vm.generateSubRoute(item.productCategoryId)}},[_vm._v(" "+_vm._s(item.title)+" ")]),(idx + 1 !== _vm.productCategories.length)?_c('div',{staticClass:"vertical-tab-divider-black"}):_vm._e(),(idx !== _vm.subTab)?_c('v-fade-transition',[(hover)?_c('div',{staticClass:"custom-tab-hover-primary"}):_vm._e()]):_vm._e()],1)]}}],null,true),model:{value:(_vm.productCategories[idx].hover),callback:function ($$v) {_vm.$set(_vm.productCategories[idx], "hover", $$v)},expression:"productCategories[idx].hover"}})})],2):_vm._e()],1),(_vm.productCategories.length > 0)?_c('calculate-form',{attrs:{"category-id":_vm.categoryId,"group-id":_vm.groupId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }